
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";


export default {
  page: {
    title: "Restaurant Category Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      tableData: [],
      title: "Restaurant Categories ",
      items: [
        {
          text: "View",
        },
        {
          text: "Restaurant Categories",
          active: true,
        },
      ],
      restaurants:[],
      branches:[],
      restaurantID:(sessionStorage.getItem('restID')>0)? [{'restID':sessionStorage.getItem('restID'),'name':sessionStorage.getItem('restName')}] : "",
      branchID:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "categoryName",
          sortable: true,
          //image: true,
        },
        {
          key: "created",
          sortable: true,
          //image: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    //this.getRestaurantCategoryList();
    this.getRestaurantList();
    sessionStorage.setItem('restID',0);
    sessionStorage.setItem('restName',"");
    this.checkUserRights();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkUserRights() {
      this.path = this.$route.path;
      this.axios.post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path,
      })
      .then((result) => {
          this.editRight = result.data.data[0].isEdit;
          this.deleteRight = result.data.data[0].isDelete;
      });
    },

    getRestaurantCategoryList(){
         this.axios
        .post(this.$loggedRole+"/getRestaurantCategoryList",
         {'restID':this.restaurantID.restID,'empTypeID':this.$storageData.profile.empTypeID})
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           //console.log((response));
          
        });
    },
    getCategories(){
      this.getRestaurantCategoryList();
      if(this.restaurantID.restID>0){ /// for maintaining state of dropdown 
         sessionStorage.setItem('restID',this.restaurantID.restID);
         sessionStorage.setItem('restName',this.restaurantID.name);
      }
         
    },

  getRestaurantList(){
    this.axios.post(this.$loggedRole+"/getRestaurantList",
    {'restID':this.$storageData.profile.restID,'restBranchID':this.$storageData.profile.restBranchID,'empID':this.$storageData.profile.pid,'empTypeID':this.$storageData.profile.empTypeID})
    .then((result)=>{
      this.restaurants = result.data.data;
        
  });
},


  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class=" col-md-4">
           <label> Choose Restaurant Brand </label>
           <multiselect v-model="restaurantID" :options="restaurants" class="mb-3" track-by="restID" label="name" @input="getCategories();"></multiselect>
    
    </div>
   
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
                <div class="row mt-4">
               <div class="col-sm-12 col-md-4" style="margin-bottom:15px;" v-if="this.$storageData.login_type == 1">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown">Excel</button> 
                       <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
                 fixed-header
              >
              
               <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;" v-if="editRight == 1"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;" v-if="deleteRight == 1"></i>
                </template>
                
                <template slot="image" slot-scope="data">
                   <img  v-bind:src="data.item.name" />  
                </template>

              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

